body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  font-family: noto-sans-kr, sans-serif;
  background-color: #f8f8f8;
  color: #4C4C4C;
  font-weight: 400;
}

@font-face {
  font-family: 'noto-sans-kr';
  font-weight: 100;
  src: url("assets/fonts/notoSans/NotoSans-Thin.woff") format("woff");
}

@font-face {
  font-family: 'noto-sans-kr';
  font-weight: 200;
  src: url("assets/fonts/notoSans/NotoSans-DemiLight.woff") format("woff");
}

@font-face {
  font-family: 'noto-sans-kr';
  font-weight: 300;
  src: url("assets/fonts/notoSans/NotoSans-Light.woff") format("woff");
}

@font-face {
  font-family: 'noto-sans-kr';
  font-weight: 400;
  src: url("assets/fonts/notoSans/NotoSans-Regular.woff") format("woff");
}

@font-face {
  font-family: 'noto-sans-kr';
  font-weight: 500;
  src: url("assets/fonts/notoSans/NotoSans-Medium.woff") format("woff");
}

@font-face {
  font-family: 'noto-sans-kr';
  font-weight: 700;
  src: url("assets/fonts/notoSans/NotoSans-Bold.woff") format("woff");
}

@font-face {
  font-family: 'noto-sans-kr';
  font-weight: 900;
  src: url("assets/fonts/notoSans/NotoSans-Black.woff") format("woff");
}
